export default {
  app: {
    state: {
      lang: localStorage.getItem('locale') || 'ru',
      translates: {},
      _save: [ 'lang', 'translates', ],
      test: {},
      askFarMark: localStorage.getItem('askFarMark') === 'true',
      filters: JSON.parse(localStorage.getItem('filters')) || {
        selectedBranches: [],
        selectedDepartments: [],
        selectedPositions: [],
        selectedLocationPoints: [],
        selectedOrganizations: [],
      },
    },
    getters: {
      askFarMark(state) {
        return state.askFarMark
      },
      filters(state) {
        return state.filters
      },
      translates(state) {
        return state.translates
      },
      lang(state) {
        return state.lang
      },
    },
    mutations: {
      lang(state, value) {
        state.lang = value
      },
      translates(state, value) {
        state.translates = value
      },
      setAskFarMark(state, value) {
        state.askFarMark = value
      },
      setFilters(state, value) {
        state.filters = {
          ...state.filters,
          ...value,
        }
        localStorage.setItem('filters', JSON.stringify(state.filters))
      },
    },
    actions: {
      setAskFarMark({ commit, }, value) {
        localStorage.setItem('askFarMark', value)
        commit('setAskFarMark', value)
      },
      updateFilters({ commit, }, value) {
        commit('setFilters', value)
      },
      updateUser() {
        return $api.auth.info().with('company').with('workSheet').then((res) => {
          const data = res.data.content
          $app.auth.user(data.user)
          $app.auth.grants(data.grants)
        })
      },
      initLang({ state, dispatch, }) {
        const lang = localStorage.getItem('locale') ? localStorage.getItem('locale') : (state.lang || 'ru')

        dispatch('setLang', lang)
      },
      setLang({ state, commit, }, value) {
        $app.api.config({
          headers: {
            'Locale': value,
          },
        })
        localStorage.setItem('locale', value)
        commit('lang', value)
        // commit('load', true)
        $api.lang.items().then((response) => {
          commit('translates', response.data.content )
          $app.api.config({
            headers: {
              'Locale': localStorage.getItem('locale'),
            },
          })
        }).finally(() => {
          // commit('load', false)
        })
      },
    },
    namespaced: true,
  },
}
