<template>
  <div class="c-filter-stats">
    <!--    <c-page-title v-if="type === 'main'" mid-text="Фильтр"/>-->
    <div class="container">
      <div class="header">
        <div class="clean" @click="clear">{{ $app.secure.clean($n.t('1.177')) }}</div>
        <div class="title">{{ $app.secure.clean($n.t('1.178')) }}</div>
        <div class="image-place" @click="type = 'main'; toggleFilterWrap()">
          <img :src="imgClose">
        </div>
      </div>
      <div class="message">
        <div class="select">
          <n-select :data="branches" :value.sync="selectedBranches" :title="$app.secure.clean($n.t('1.65'))" />
        </div>
        <div class="select">
          <n-select :data="departments" :value.sync="selectedDepartments" :title="$app.secure.clean($n.t('1.66'))" />
        </div>
        <div class="select">
          <n-select :data="locationPoints" :value.sync="selectedLocationPoints" :title="$app.secure.clean($n.t('1.67'))" />
        </div>
        <div v-if="$app.auth.user().successCard === 'bazis'" class="select">
          <n-select :data="organizations" :value.sync="selectedOrganizations" :title="$app.secure.clean($n.t('1.179'))" />
        </div>
        <div class="select">
          <n-select :data="positions" :value.sync="selectedPositions" :title="$app.secure.clean($n.t('1.68'))" />
        </div>
      </div>
      <n-button class="user-default-button purple" @click="show">{{ $app.secure.clean($n.t('1.180')) }}</n-button>
    </div>
  </div>
</template>

<script>

// import closeicon from '../../assets/profile/close-icon.svg'
import imgRow from '../../assets/page-fixation/nav-link-arrow.svg'
import imgBack from '../../assets/page-title-icon/back-arrow.svg'
import imgClose from '../../assets/page-fixation/close-icon.svg'
import imgLabel from '../../assets/page-timesheet/label-icon.svg'

export default {
  name: 'CFilterStats',
  props: {
    yes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // closeicon,
      imgRow,
      imgBack,
      imgClose,
      imgLabel,
      select: false,
      type: 'main',
      openFilter: false,
      items: [],
      branches: [],
      departments: [],
      locationPoints: [],
      organizations: [],
      positions: [],
      selectedBranches: [],
      selectedPositions: [],
      selectedDepartments: [],
      selectedOrganizations: [],
      selectedLocationPoints: [],
      types: [
        {
          title: $app.secure.clean($n.t('1.31')),
          value: 'late',
        },
        {
          title: $app.secure.clean($n.t('1.32')),
          value: 'sick',
        },
        {
          title: $app.secure.clean($n.t('1.33')),
          value: 'freeVac',
        },
        {
          title: $app.secure.clean($n.t('1.34')),
          value: 'workVac',
        },
        {
          title: $app.secure.clean($n.t('1.35')),
          value: 'addVac',
        },
        {
          title: $app.secure.clean($n.t('1.36')),
          value: 'weekend',
        },
        {
          title: $app.secure.clean($n.t('1.37')),
          value: 'finance',
        },
      ],
    }
  },
  computed: {
    data() {
      return {
        selectedBranches: this.turnIntoIdsArray(this.selectedBranches),
        selectedDepartments: this.turnIntoIdsArray(this.selectedDepartments),
        selectedPositions: this.turnIntoIdsArray(this.selectedPositions),
        selectedLocationPoints: this.turnIntoIdsArray(this.selectedLocationPoints),
        selectedOrganizations: this.turnIntoIdsArray(this.selectedOrganizations),
      }
    },
  },
  created() {
    this.loadBranches()
    this.loadDepartments()
    this.loadLocationPoints()
    this.loadPositions()
    if ($app.auth.user().successCard === 'bazis') {
      this.loadOrganizations()
    }
    this.initFilters()
  },
  methods: {
    clear() {
      this.selectedPositions = []
      this.selectedOrganizations = []
      this.selectedDepartments = []
      this.selectedBranches = []
      this.selectedLocationPoints = []

      this.close()
    },
    initFilters() {
      const filters = $app.store.getter('app.filters')
      this.selectedBranches = filters.selectedBranches
      this.selectedDepartments = filters.selectedDepartments
      this.selectedPositions = filters.selectedPositions
      this.selectedLocationPoints = filters.selectedLocationPoints
      this.selectedOrganizations = filters.selectedOrganizations
      this.$emit( 'update:filters', this.data )
    },
    selectItem(field, v) {
      if (this['selected'+field].includes(v.id)) {
        this['selected'+field] = $n.filter(this['selected'+field], (e) => {
          if (e !== v.id) {
            return e
          }
        })
      } else {
        this['selected'+field].push(v.id)
      }
    },
    loadBranches() {
      this.$var('load', true)
      $api.companies.getBranches($app.auth.user().companyId).all().then((response) => {
        this.branches = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadDepartments() {
      this.$var('load', true)
      $api.companies.getDepartments($app.auth.user().companyId).all().then((response) => {
        this.departments = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadLocationPoints() {
      this.$var('load', true)
      $api.companies.getLocationPoints($app.auth.user().companyId).all().then((response) => {
        this.locationPoints = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadOrganizations() {
      this.$var('load', true)
      $api.companies.getOrganizations($app.auth.user().companyId).all().then((response) => {
        this.organizations = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadPositions() {
      this.$var('load', true)
      $api.companies.getPositions($app.auth.user().companyId).all().then((response) => {
        this.positions = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    close() {
      this.$emit( 'filter-action', false )
      this.saveFilters()
      this.$emit( 'update:filters', this.data )
    },
    toggleFilterWrap() {
      this.openFilter = false

      this.saveFilters()
      this.$emit( 'filter-action', this.openFilter )
      this.$emit( 'update:filters', this.data )
    },
    saveFilters() {
      $app.store.action('app.updateFilters', {
        selectedBranches: this.selectedBranches,
        selectedDepartments: this.selectedDepartments,
        selectedPositions: this.selectedPositions,
        selectedLocationPoints: this.selectedLocationPoints,
        selectedOrganizations: this.selectedOrganizations,
      })
    },
    show() {
      this.toggleFilterWrap()
    },
    turnIntoIdsArray(array) {
      return $n.map(array, (e) => {
        return e.id
      })
    },
  },
}
</script>

<style scoped lang="scss">


.c-filter-stats {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #F5F6F9;
  padding: calc(env(safe-area-inset-top) + 50px) 0 50px 0;


  .message {
    .nav-list {
      margin-bottom: 20px;

      .nav-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;
        border-bottom: 1px solid #EFF0F4;
        margin: 16px 0 0 0;
        text-decoration: none;
        background: #fff;
        border-radius: 16px;

        .left-part {
          display: flex;

          .image-place {
            height: 24px;
            width: 24px;

            img {
              width: 100%;
              height: 100%;
            }

          }

          .title {
            margin-left: 15px;
            font-size: 15px;
            color: var(--text-color);
          }
        }

        .right-part {
          margin-right: 20px;
        }
      }
    }
  }
}

.header {
  height: calc(env(safe-area-inset-top) + 50px);
  background: #fff;
  position: fixed;
  padding: env(safe-area-inset-top) 24px 0 24px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .clean {
    font-size: 15px;
    font-weight: 700;
    color: #828282;
    width: 37%;
  }

  .image-place {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    width: 30%;
  }

  .title {
    font-size: 15px;
    font-weight: 600;
    width: 33%;
    text-align: center;
  }
}
.select {
  .n-select {
    min-height: 50px;
    height: auto;
  }
  margin-top: 5px;
}

.branch-wrap {
  margin-top: 20px;
  max-height: 75vh;
  overflow: auto;

  .select-wrap {
    background: #fff;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.active {
      border: 1px solid #7C55FE;
    }

    .check-box {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #BDBDBD;

      &.active {
        border: 7px solid #7C55FE;
      }
    }


    .title {
      font-size: 15px;
      max-width: 80%;
    }

    .count {
      font-size: 15px;
      font-weight: 700;
      color: #62BF40;
    }

    span {
      font-size: 15px;
      font-weight: 700;
      color: #828282;
    }
  }
   .point {
     display: flex;
     justify-content: left;

     .title {
       margin: 0 16px;

       .address {
         display: flex;
         font-size: 13px;
         margin-top: 4px;

         .image-place {
           margin-right: 5px;
         }
       }
     }
   }
}

.close-modal {
  display: none;
}
</style>
