<template>
  <div class="scanner">
    <div class="aim-wrapper">
      <div class="help-wrap">
        <div class="help">{{ $app.secure.clean($n.t('1.53')) }}</div>
      </div>
      <div class="aim">
        <n-icon icon="aim" />
        <div class="corner top-left"></div>
        <div class="corner top-right"></div>
        <div class="corner bottom-left"></div>
        <div class="corner bottom-right"></div>
      </div>
    </div>
  </div>
</template>

<script>

// import { BarcodeScanner, } from '@capacitor-community/barcode-scanner'
import { BarcodeScanner, } from '@capacitor-mlkit/barcode-scanning'

export default {
  name: 'CScanner',
  props: {
    abletoscan: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    abletoscan() {
      if (this.abletoscan) {
        this.startScan()
      }
    },
  },
  beforeDestroy() {
    BarcodeScanner.stopScan()
    BarcodeScanner.removeAllListeners()
  },
  methods: {
    async startScan() {
      try {
        // Скрыть фон и показать камеру
        document.querySelector('body')?.classList.add('barcode-scanner-active')

        // Запуск сканера
        await BarcodeScanner.startScan()

        // Добавляем слушатель события сканирования
        await BarcodeScanner.addListener('barcodeScanned', async (result) => {
          if (result && result.barcode) {
            const obj = result
            this.stop().then(() => {
              this.$emit('scanned', obj)
            })

            // Останавливаем сканирование после успешного сканирования
          }
        })
      } catch (error) {
        console.error('Ошибка запуска сканирования: ', error)
      }
    },
    async stop() {
      await BarcodeScanner.stopScan()
      await BarcodeScanner.removeAllListeners()
    },
  },
}
</script>

<style lang="scss" scoped>
.scanner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .aim-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .help-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      .help {
        color: #fff;
        padding: 12px;
        background: #101010;
        border-radius: 12px;
        font-weight: 600;
        font-size: 15px;
        z-index: 12;
      }
    }
    .aim {
      z-index: -100;
      box-shadow: 0 4px 50px 500px rgba(9, 0, 0, 0.3);
      position: relative;
      width: 250px;
      height: 250px;
      border-radius: 15px;
      background: unset;

      .corner {
        position: absolute;
        width: 30px; /* Adjust size to your preference */
        height: 30px; /* Adjust size to your preference */
        border: 6px solid #7C55FE;
      }
      .top-left {
        top: 0;
        left: 0;
        border-right: none;
        border-bottom: none;
        border-top-left-radius: 15px; /* Adjust radius to your preference */
      }
      .top-right {
        top: 0;
        right: 0;
        border-left: none;
        border-bottom: none;
        border-top-right-radius: 15px; /* Adjust radius to your preference */
      }
      .bottom-left {
        bottom: 0;
        left: 0;
        border-right: none;
        border-top: none;
        border-bottom-left-radius: 15px; /* Adjust radius to your preference */
      }
      .bottom-right {
        bottom: 0;
        right: 0;
        border-left: none;
        border-top: none;
        border-bottom-right-radius: 15px; /* Adjust radius to your preference */
      }
    }
  }
}
</style>
