/**
 * 
 */
export default class RestApi {
  test = {
    test: () => $app.api.get([ 'test', ]),
  }

  auth = {
    login: (data) => $app.api.post([ 'new/auth/login', ]).with('company').data(data),
    info: () => $app.api.get([ 'auth/info', ]),

    check: (data) => $app.api.post([ 'new/auth/check', ]).data(data),
    sendCode: (data) => $app.api.post([ 'new/auth/send-code', ]).data(data),
    checkCode: (data) => $app.api.post('new/auth/check-code').data(data),
    register: (data) => $app.api.patch([ 'new/auth/register', ]).with('company').data(data),

    recoverCheck: (data) => $app.api.post([ 'new/auth/recover/check', ]).data(data),
    recover: (data) => $app.api.patch('new/auth/recover').data(data),

    deleteAcc: (id) => $app.api.post([ 'users*/delete-acc', id, ]),
  }

  files = {
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },
  }

  companies = {
    getActivePeople: (companyId, data) => $app.api.post([ 'companies*/active-people-new', companyId, ]).data(data),
    getActivePeopleAll: (companyId, data) => $app.api.post([ 'companies*/active-people', companyId, ]).data(data),
    getDepartments: (companyId, id) => $app.api.get([ 'companies*/departments*', companyId, id, ]),
    getLocationPoints: (companyId, id) => $app.api.get([ 'companies*/location-points*', companyId, id, ]),
    getBranches: (companyId, id) => $app.api.get([ 'companies*/branches*', companyId, id, ]),
    getOrganizations: (companyId, id) => $app.api.get([ 'companies*/organizations*', companyId, id, ]),
    getPositions: (companyId) => $app.api.get([ 'companies*/positions*', companyId, ]),

  }

  application = {
    getMy: (companyId) => $app.api.get([ 'my/companies*/applications', companyId, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/applications', companyId, ]).data(data),
  }

  notifications = {
    get: () => $app.api.get([ 'notifications-my', ]),
    markRead: (data) => $app.api.post([ 'notifications-my/mark-read', ]).data(data),
    checkNew: () => $app.api.get( [ 'notifications-my/has-new', ]),
  }

  user = {
    editPass: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
    get: (id) => $app.api.get([ 'users*', id, ]),
    getAll: (companyId) => $app.api.get([ 'companies*/users', companyId, ]),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    
    myStats: (data) => $app.api.post([ 'my/visit-stats', ]).data(data),
  }

  locationPoints = {
    get: (companyId, id) => $app.api.get([ 'companies*/location-points*', companyId, id, ]),
  }

  visits = {
    get: () => $app.api.get([ 'visits', ]),
    create: (companyId, branchId, data) => $app.api.post([ 'companies*/branches*/visits', companyId, branchId, ]).data(data),
    offline: (companyId, data) => $app.api.post([ 'companies*/visits-offline', companyId, ]).data(data),
    farV: (companyId, data) => $app.api.post([ 'companies*/visits-far', companyId, ]).data(data),
    createByLocPoint: (companyId, locPointId, data) => $app.api.post([ 'companies*/location-points*/visits', companyId, locPointId, ]).data(data),
    createAdmin: (companyId, userId, data) => $app.api.post([ 'visits/companies*/users*', companyId, userId, ]).data(data),
  }

  fcmPush = {
    save: (data) => $app.api.post('device/save').data(data),
    deleteToken: (data) => $app.api.delete('device/delete').data(data),
  }

  documents = {
    get: (companyId) => $app.api.get([ 'companies*/documents*', companyId, ]),
  }

  lang = {
    locales: (lang) => $app.api.get([ 'locales', ]),
    items: () => $app.api.get([ 'translations/content', ]),
  }
}
