<template>
  <div class="c-code-input">
    <div class="code-inputs" @click="setFocus">
      <div class="number-place" :class="{'active-cell': activeCell === 1}">{{ code[0] }}</div>
      <div class="number-place" :class="{'active-cell': activeCell === 2}">{{ code[1] }}</div>
      <div class="number-place" :class="{'active-cell': activeCell === 3}">{{ code[2] }}</div>
      <div class="number-place" :class="{'active-cell': activeCell === 4}">{{ code[3] }}</div>
    </div>
    <div v-if="danger" class="danger">{{danger}}</div>
    <input id="input-number" v-model="rawCode" type="tel" maxlength="4" class="hidden-input" @input="check">
  </div>
</template>

<script>
export default {
  name: 'CCodeInput',
  props: {
    received: {
      type: String,
      default: '',
    },
    danger: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rawCode: '',
      code: '',
      focusNum: 0,
      activeCell: 1,
    }
  },
  watch: {
    received() {
      if (this.received.length === 4) {
        this.code = this.received
      }
    },
    rawCode() {
      this.rawCode = this.rawCode.replace(/[^0-9]/g, '')
      if (Number(this.rawCode[this.rawCode.length-1]) || this.rawCode[this.rawCode.length-1] === '0') {
        this.code = this.code.substring(0, this.rawCode.length-1) + this.rawCode[this.rawCode.length-1]
        if (this.rawCode.length === 0) {
          this.code = ''
        }
      }
    },
    code() {
      this.activeCell = this.code.length+1
      if (this.code.length === 4) {
        this.$emit('update:code', this.code)
        this.$emit('ready', this.code)
      }
    },
  },
  mounted() {
    this.setFocus()
    document.getElementById('input-number').value = this.code
  },
  methods: {
    setFocus() {
      document.getElementById('input-number').focus()
    },
    check() {
      if (!document.getElementById('input-number').value) {
        this.code = ''
      }
      if (document.getElementById('input-number').value.length === 4) {
        this.code = document.getElementById('input-number').value
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-code-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  .danger {
    font-size: 13px;
    text-align: center;
    color: #FF0000;
  }

  .hidden-input {
    z-index: -1;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  .code-inputs {
    margin: 0 0 8px;
    display: flex;
    align-items: center;

    .number-place {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 3px;
      width: 64px;
      height: 64px;
      padding: 5px;
      font-weight: 400;
      background-color: var(--secondary);
      color: var(--text-color);
      border-radius: 12px;
      font-size: 26px;
      &:placeholder-shown { border-bottom: 3px solid #E3000F; }
      &:focus-visible { outline: none; }
      &:focus { outline: none; }
      &::placeholder { opacity: 0; }
      &.filled {
        background-color: transparent;
        color: #000;
      }
      &.active-cell {
        border: 1px solid #7C55FE;
      }
    }
  }
}
</style>
