<template>
  <div class="c-pagination">
    <div class="pagination-row">
      <!-- Кнопка для перехода на предыдущую страницу -->
      <div class="selector" :class="{ disabled: page === 1 }" @click="previousPage">←</div>

      <!-- Первая страница -->
      <div
        v-if="pageCount > 1"
        class="selector"
        :class="{ active: page === 1 }"
        @click="goToPage(1)"
      >
        1
      </div>

      <!-- Левое многоточие -->
      <div v-if="showLeftEllipsis" class="ellipsis">...</div>

      <!-- Отображение номеров страниц вокруг текущей -->
      <div
        v-for="number in middlePages"
        :key="number"
        class="selector"
        :class="{ active: number === page }"
        @click="goToPage(number)"
      >
        {{ number }}
      </div>

      <!-- Правое многоточие -->
      <div v-if="showRightEllipsis" class="ellipsis">...</div>

      <!-- Последняя страница -->
      <div
        v-if="pageCount > 1 && pageCount !== 1"
        class="selector"
        :class="{ active: page === pageCount }"
        @click="goToPage(pageCount)"
      >
        {{ pageCount }}
      </div>

      <!-- Кнопка для перехода на следующую страницу -->
      <div class="selector" :class="{ disabled: page === pageCount }" @click="nextPage">→</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPagination',
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    middlePages() {
      let pages = []
      if (this.pageCount < this.pageCount) {
        // Если всего страниц 6 или меньше, показываем все
        pages = Array.from({ length: this.pageCount, }, (_, i) => i + 1)
      } else {
        // Отображаем страницы вокруг текущей
        const startPage = Math.max(this.page - 1, 2)
        const endPage = Math.min(this.page + 1, this.pageCount - 1)

        // Генерируем диапазон страниц
        pages = Array.from({ length: endPage - startPage + 1, }, (_, i) => startPage + i)
      }
      return pages
    },
    showLeftEllipsis() {
      return this.page > 3
    },
    showRightEllipsis() {
      return this.page < this.pageCount - 2
    },
  },
  methods: {
    goToPage(pageNumber) {
      this.$emit('update:page', pageNumber) // Передаем выбранную страницу родителю
    },
    previousPage() {
      if (this.page > 1) {
        this.goToPage(this.page - 1)
      }
    },
    nextPage() {
      if (this.page < this.pageCount) {
        this.goToPage(this.page + 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pagination {
  display: flex;
  align-items: center;
  justify-content: right;

  .pagination-row {
    display: flex;
    align-items: center;

    .selector {
      color: var(--primary);
      font-size: 16px;
      margin: 0 5px;
      cursor: pointer;

      &.disabled {
        color: #ccc;
        pointer-events: none;
      }

      &.active {
        font-weight: bold;
        border-bottom: 2px solid var(--primary);
      }
    }

    .ellipsis {
      font-size: 16px;
      margin: 0 5px;
      color: #999;
    }
  }
}
</style>
