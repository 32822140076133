import CMobileAppbar from 'components/mobile-app-bar/Index.vue'
import CMyCalendar from 'components/my-calendar/Index'
import CPageTitle from 'components/page-title/Index'
import CFilterStats from 'components/filter-stats/Index'
import CFilterDateStats from 'components/filter-date-stats/Index'
import CRadio from 'components/c-radio/Index'
import CSearchModal from 'components/search-modal/Index'
import CDatePicker from 'components/date-picker/date-picker/Index'
import Select from 'components/New-select/New-select/Index'
import CModalMessage from 'components/modal-message/Index'
import CQrModalMessage from 'components/qr-modal-message/Index'
import CModalComment from 'components/modal-comment/Index'
import CCodeInput from 'components/code-input/Index'
import CScanner from 'components/scanner/Index'
import CMonthPicker from 'components/month-picker/Index.vue'
import CProgress from 'components/progress-bar/Index.vue'
import CPagination from 'components/c-paginaton/Index.vue'
import T from './t/Index'

export default {
  CMobileAppbar,
  CMyCalendar,
  CPageTitle,
  CFilterStats,
  CFilterDateStats,
  CRadio,
  CSearchModal,
  Select,
  CDatePicker,
  CModalMessage,
  CQrModalMessage,
  CModalComment,
  CCodeInput,
  CScanner,
  CMonthPicker,
  CProgress,
  CPagination,
  T,
}
