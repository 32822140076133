<template>
  <div class="c-my-calendar">
    <!--    <div v-if="isOpen" class="invisible-close" @click="open" />-->
    <!--    <div class="date-place" @click="open">-->
    <!--      <span>{{ dateForView }}</span>-->
    <!--    </div>-->

    <div class="calendar">
      <!--      <div class="top year">-->
      <!--        <div class="dec" @click="year&#45;&#45;">&#9660;</div>-->
      <!--        <div class="date"> {{ year }} </div>-->
      <!--        <div class="inc" @click="year++">&#9660;</div>-->
      <!--      </div>-->
      <div class="top">
        <div class="dec" @click="decrease">{{ openBody ? '←' : '' }}</div>
        <div class="date"> {{ months[month] }} {{ year }}</div>
        <div class="inc" @click="increase">{{ openBody ? '→' : '' }}</div>
      </div>
      <div class="header">
        <table>
          <tr>
            <th v-for="(d, index) in day" :key="index" class="week-day">{{ d }}</th>
          </tr>
        </table>
      </div>
      <div class="table-wrapper">
        <table v-if="openBody" class="calendar-table">
          <tr v-for="(week, index) in calendarWeek()" :key="index" class="week">
            <td @click="chooseDay(week[1])">
              <div
                class="number" :class="{
                  'active': compareDate(week[1]?.index),
                  'between': isBetween(week[1]),
                  'gone': isGone(week[1]),
                  'today': isToday(week[1])
                }">
                {{ week[1]?.index }}
                <div v-if="week[1]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[1]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[2])">
              <div
                class="number" :class="{
                  'active': compareDate(week[2]?.index),
                  'between': isBetween(week[2]),
                  'gone': isGone(week[2]),
                  'today': isToday(week[2])
                }">
                {{ week[2]?.index }}
                <div v-if="week[2]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[2]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[3])">
              <div
                class="number" :class="{
                  'active': compareDate(week[3]?.index),
                  'between': isBetween(week[3]),
                  'gone': isGone(week[3]),
                  'today': isToday(week[3])
                }">
                {{ week[3]?.index }}
                <div v-if="week[3]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[3]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[4])">
              <div
                class="number" :class="{
                  'active': compareDate(week[4]?.index),
                  'between': isBetween(week[4]),
                  'gone': isGone(week[4]),
                  'today': isToday(week[4])
                }">
                {{ week[4]?.index }}
                <div v-if="week[4]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[4]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[5])">
              <div
                class="number" :class="{
                  'active': compareDate(week[5]?.index),
                  'between': isBetween(week[5]),
                  'gone': isGone(week[5]),
                  'today': isToday(week[5])
                }">
                {{ week[5]?.index }}
                <div v-if="week[5]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[5]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[6])">
              <div
                class="number" :class="{
                  'active': compareDate(week[6]?.index),
                  'between': isBetween(week[6]),
                  'gone': isGone(week[6]),
                  'today': isToday(week[6])
                }">
                {{ week[6]?.index }}
                <div v-if="week[6]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[6]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[0])">
              <div
                class="number" :class="{
                  'active': compareDate(week[0]?.index),
                  'between': isBetween(week[0]),
                  'gone': isGone(week[0]),
                  'today': isToday(week[0])
                }">
                {{ week[0]?.index }}
                <div v-if="week[0]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[0]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <table v-if="!openBody" class="calendar-table">
          <tr v-for="(week, index) in calendar()" :key="index" class="week">
            <td @click="chooseDay(week[1])">
              <div
                class="number" :class="{
                  'active': compareDate(week[1]?.index),
                  'between': isBetween(week[1]),
                  'gone': isGone(week[1]),
                  'today': isToday(week[1])
                }">
                {{ week[1]?.index }}
                <div v-if="week[1]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[1]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[2])">
              <div
                class="number" :class="{
                  'active': compareDate(week[2]?.index), 
                  'between': isBetween(week[2]),
                  'gone': isGone(week[2]),
                  'today': isToday(week[2])
                }">
                {{ week[2]?.index }}
                <div v-if="week[2]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[2]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[3])">
              <div
                class="number" :class="{
                  'active': compareDate(week[3]?.index),
                  'between': isBetween(week[3]),
                  'gone': isGone(week[3]),
                  'today': isToday(week[3])
                }">
                {{ week[3]?.index }}
                <div v-if="week[3]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[3]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[4])">
              <div
                class="number" :class="{
                  'active': compareDate(week[4]?.index), 
                  'between': isBetween(week[4]), 
                  'gone': isGone(week[4]),
                  'today': isToday(week[4])
                }">
                {{ week[4]?.index }}
                <div v-if="week[4]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[4]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[5])">
              <div
                class="number" :class="{
                  'active': compareDate(week[5]?.index),
                  'between': isBetween(week[5]), 
                  'gone': isGone(week[5]),
                  'today': isToday(week[5])
                }">
                {{ week[5]?.index }}
                <div v-if="week[5]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[5]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[6])">
              <div
                class="number" :class="{
                  'active': compareDate(week[6]?.index),
                  'between': isBetween(week[6]),
                  'gone': isGone(week[6]),
                  'today': isToday(week[6])
                }">
                {{ week[6]?.index }}
                <div v-if="week[6]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[6]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
            <td @click="chooseDay(week[0])">
              <div
                class="number" :class="{
                  'active': compareDate(week[0]?.index),
                  'between': isBetween(week[0]),
                  'gone': isGone(week[0]),
                  'today': isToday(week[0])
                }">
                {{ week[0]?.index }}
                <div v-if="week[0]?.date" class="circle-wrap">
                  <div class="circle" :style="{'background': types[workDays?.[getYNum(week[0]?.date)]?.type]?.color, }"></div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="!openBody" class="open-calendar" @click="openBody = true">
        <span> {{ $app.secure.clean($n.t('1.23')) }} </span>
        <div class="image-place">
          <img :src="imgOpen">
        </div>
      </div>
      <div v-if="openBody" class="open-calendar" @click="openBody = false">
        <span> {{ $app.secure.clean($n.t('1.222')) }} </span>
        <div class="image-place">
          <img :src="imgOpen" :class="'rotated'">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import next from '../../assets/next-row-gray.svg'
// import back from '../../assets/back-row-gray.svg'
import imgOpen from '../../assets/page-timesheet/open-arrow.svg'

export default {
  name: 'CMyCalendar',
  props: {
    date: {
      type: Array,
      default: () => [ new Date(), ],
    },
    workDays: {
      type: Object,
      required: true,
    },
    ablePeriod: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // next,
      // back,
      imgOpen,
      openBody: false,
      today: new Date(),
      selectedDates: this.date,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
        en: [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', ],
      },
      dateForView: '',
      day: [],
      cDate: new Date,
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
        en: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ],
      },
      months: [],
      dlast: new Date(this.year, this.month + 1, 0).getDate(),
      isOpen: false,
      types: {
        beforeWork: { title: $app.secure.clean($n.t('1.184')), color: '#999999', },
        workDay: { title: $app.secure.clean($n.t('1.25')), color: '#ffffff', },
        work: { title: $app.secure.clean($n.t('1.25')), color: '#ffffff', },
        celebrate: { title: $app.secure.clean($n.t('1.190')), color: '#FFAF36', },
        sickLeave: { title: $app.secure.clean($n.t('1.193')), color: '#3AE2CE', },
        weekend: { title: $app.secure.clean($n.t('1.196')), color: '#CFCECE', },
        laborLeave: { title: $app.secure.clean($n.t('1.185')), color: '#F1CB04', },
        businessTrip: { title: $app.secure.clean($n.t('1.188')), color: '#7C1DC9', },
        was: { title: $app.secure.clean($n.t('1.25')), color: '#64C038', },
        wasInWeekend: { title: $app.secure.clean($n.t('1.196')), class: 'wasInWeekend', color: 'linear-gradient(315deg, #64C038 45%, #CFCECE 65%)', },
        freeVacation: { title: $app.secure.clean($n.t('1.197')), color: '#FAFF00', },
        weekendBusinessTrip: { title: $app.secure.clean($n.t('1.186')), color: '#9F79C8', },
        wasnt: { title: $app.secure.clean($n.t('1.25')), color: '#FF0000', },
        maternityLeave: { title: $app.secure.clean($n.t('1.192')), color: '#FF008A', },
        terminationTD: { title: $app.secure.clean($n.t('1.195')), color: '#606060', },
        late: { title: $app.secure.clean($n.t('1.25')), color: '#A70F61', },
        additionalVac: { title: $app.secure.clean($n.t('1.187')), color: '#BD5306', },
      },
    }
  },
  watch: {
    date() {
      this.init()
    },
    cDate() {
      this.updateView()
    },
  },
  created() {
    this.init()
  },
  methods: {
    getYNum(date) {
      if (date) {
        // Получаем первый элемент массива (дату)
        // Создаем объект даты на 1 января текущего года
        const startOfYear = new Date(date.getFullYear(), 0, 1)

        // Разница в миллисекундах между текущей датой и 1 января
        const diffInMilliseconds = date - startOfYear

        // Преобразуем разницу в дни
        return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24)) + 1// Возвращаем порядковый номер дня в году
      }
    },
    open() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen
      }
    },
    init() {
      const lang = localStorage.getItem('locale') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    updateView() {
      if (this.month >= 0 && this.month < this.months?.length) {
        this.dateForView = (this.cDate.getDate() <= 9 ? '0' + this.cDate.getDate() : this.cDate.getDate()) +
            ' ' + this.months[this.month].substr(0, 3) + '. ' + this.year
      }
    },
    isGone(day) {
      if (!day) {
        return false
      }

      return day.date.getTime() < new Date().getTime() && $app.date.format(day.date, 'date') !== $app.date.format(new Date(), 'date')
    },
    isToday(day) {
      if (!day) {
        return false
      }

      return $app.date.format(day.date, 'date') === $app.date.format(this.today, 'date')
    },
    isBetween(day) {
      if (!day) {
        return false
      }
      if (this.selectedDates?.length > 1) {
        return this.selectedDates[0].getTime() < day.date.getTime() && this.selectedDates[1].getTime() > day.date.getTime()
      } else {
        return false
      }
    },
    chooseDay(day) {
      day = day?.index
      const selectedDate = new Date(this.year, this.month, day)
      const selectedIndex = this.selectedDates.findIndex((date) =>
        $app.date.format(date, 'date') === $app.date.format(selectedDate, 'date')
      )
      if (this.ablePeriod) {
        if (selectedIndex === -1) {
          this.selectedDates.push(selectedDate)
        } else {
          this.selectedDates.splice(selectedIndex, 1)
        }

        if (this.selectedDates?.length > 2) {
          this.selectedDates = [ selectedDate, ]
        }

        // Сортировка выбранных дат
        this.selectedDates.sort((a, b) => a - b)
      } else {
        this.selectedDates = [ selectedDate, ]
      }


      this.$emit('update:value', this.selectedDates)
      this.$emit('change')
      this.$emit('input', this.selectedDates)
      this.isOpen = false
    },
    compareDate(day) {
      const currentDate = new Date(this.year, this.month, day).setHours(0, 0, 0, 0)
      return this.selectedDates.some((selectedDate) => {
        const selectedDateCopy = new Date(selectedDate).setHours(0, 0, 0, 0)
        return currentDate === selectedDateCopy
      })
    },
    calendarWeek() {
      const weeks = {}
      let week = 0
      let days = {}
      // const firstDay = new Date(this.year, this.month, 1).getDay()
      const dlast = new Date(this.year, this.month + 1, 0).getDate()
      // const prevMonthDays = new Date(this.year, this.month, 0).getDate()

      // // Дни предыдущего месяца
      // for (let i = firstDay - 1; i >= 0; i--) {
      //   const day = prevMonthDays - i
      //   const date = new Date(this.year, this.month - 1, day)
      //   days[date.getDay()] = { index: day, date: date, }
      // }

      // Дни текущего месяца
      for (let i = 1; i <= dlast; i++) {
        const date = new Date(this.year, this.month, i)
        days[date.getDay()] = { index: i, date: date, }
        if (date.getDay() === 0) {
          weeks[week] = days
          week += 1
          days = {}
        }
      }

      weeks[week] = days
      return weeks
    },
    calendar: function() {
      const weeks = {}
      const days = {}
      const today = new Date()
      const dayOfWeek = today.getDay() || 7 // Если воскресенье (0), то считаем как 7

      let firstDayOfWeek = new Date(today.setDate(today.getDate() - dayOfWeek + 1))

      if (today.getMonth() !== this.month) {
        firstDayOfWeek = new Date(new Date(new Date().setMonth(this.month)).setDate(1))
      }

      const week = this.getWeekOfMonth(firstDayOfWeek)
      // Собирать дни недели
      for (let i = 0; i < 7; i++) {
        const date = new Date(firstDayOfWeek)
        date.setDate(date.getDate() + i)
        if (week === this.getWeekOfMonth(date)) {
          days[date.getDay()] = { index: date.getDate(), date: date, }
        }
      }

      weeks[week] = days
      return weeks
    },
    getWeekOfMonth(date) {
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
      const dayOfMonth = date.getDate()
      const dayOfWeek = firstDayOfMonth.getDay() || 7 // Учитываем начало недели с понедельника (если день недели воскресенье, считаем его как 7)

      // Считаем номер недели в месяце
      return Math.ceil((dayOfMonth + dayOfWeek - 1) / 7) + 1
    },
    emitYear() {
      this.$emit('update-year', this.year)
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
      }
      this.emitYear()
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
      }
      this.emitYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.c-my-calendar {
  position: relative;
  width: 100%;

  .invisible-close {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .date-place {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: black;
    background: inherit;
    border-radius: 12px;
    padding: 0;
  }

  .calendar {
    min-height: 200px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #EFF0F4;
    z-index: 20;
    padding: 5px 0;
    border-radius: 16px;

    .header {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      th {
        padding: 10px;
        width: 50px;
        color: #75809E;
        height: 22px;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px;
      background: #F5F6F9;
      margin: 5px 10px 10px 10px;
      border-radius: 12px;
      height: 48px;

      .dec {
        font-size: 18px;
        //transform: rotate(90deg);
      }

      .inc {
        font-size: 18px;
        //transform: rotate(-90deg);
      }

      .date {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .table-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .calendar-table {
      border-collapse: collapse;

      td {
        padding: 5px;
        width: 50px;
        height: 50px;
        font-size: 17px;
        font-weight: 600;
        //color: #D4D8E1;
        color: var(--text-color);

        .number {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          height: 100%;
          width: 100%;
          color: #000;

          &.gone {
            color: #BDBDBD;
          }

          &.today {
            background: #EFF0F4;
          }

          &.between {
            background: #dee8fe;
          }

          &.active {
            color: #fff;
            background: #2F2F2F;
            font-size: 15px !important;
          }

          .circle-wrap {
            display: flex;

            .circle {
              margin: 1px;
              width: 18px;
              height: 4px;
              background: #ffffff;
              border-radius: 4px;
              border: 0.1px solid #2F2F2F;
            }
          }
        }
      }
    }

    .open-calendar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      color: #828282;
      font-size: 13px;
      font-weight: 500;
      border-top: 1px solid #F2F2F2;
      margin-top: 20px;

      .image-place {
        margin: 0 5px;
      }

      .rotated {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }
  }
}
</style>
