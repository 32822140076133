<template>
  <div class="c-month-picker">
    <div class="calendar">
      <div class="top">
        <div class="dec" @click="decrease">←</div>
        <div class="date"> {{ months[month] }} {{ year }}</div>
        <div class="inc" @click="increase">→</div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'CMonthPicker',
  props: {
  },
  data() {
    return {
      today: new Date(),
      selectedDates: this.date,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
        en: [ 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', ],
      },
      dateForView: '',
      day: [],
      cDate: new Date,
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
        en: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ],
      },
      months: [],
    }
  },
  watch: {
    date() {
      this.init()
    },
    cDate() {
      this.updateView()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const lang = localStorage.getItem('locale') || 'ru'
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
      this.updateView()
    },
    updateView() {
      if (this.month >= 0 && this.month < this.months?.length) {
        this.dateForView = (this.cDate.getDate() <= 9 ? '0' + this.cDate.getDate() : this.cDate.getDate()) +
            ' ' + this.months[this.month].substr(0, 3) + '. ' + this.year
      }
    },
    updateDates() {
      const dates = [
        new Date(this.year, this.month, 1),
        new Date(this.year, this.month + 1, 0),
      ]

      this.$emit('update:dates', dates)
    },
    decrease() {
      this.month--
      if (this.month < 0) {
        this.month = 12
        this.month--
        this.year--
      }
      this.updateDates()
    },
    increase() {
      this.month++
      if (this.month > 11) {
        this.month = -1
        this.month++
        this.year++
      }
      this.updateDates()
    },
  },
}
</script>

<style lang="scss" scoped>
.c-month-picker {
  position: relative;
  width: 100%;

  .invisible-close {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .date-place {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: black;
    background: inherit;
    border-radius: 12px;
    padding: 0;
  }

  .calendar {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #EFF0F4;
    z-index: 20;
    padding: 5px 0;
    border-radius: 16px;

    .header {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      th {
        padding: 10px;
        width: 50px;
        color: #75809E;
        height: 22px;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px;
      background: #F5F6F9;
      margin: 5px 10px 10px 10px;
      border-radius: 12px;
      height: 48px;

      .dec {
        font-size: 18px;
        //transform: rotate(90deg);
      }

      .inc {
        font-size: 18px;
        //transform: rotate(-90deg);
      }

      .date {
        font-size: 15px;
        font-weight: 600;
      }
    }

    .table-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .calendar-table {
      border-collapse: collapse;

      td {
        padding: 5px;
        width: 50px;
        height: 50px;
        font-size: 17px;
        font-weight: 600;
        //color: #D4D8E1;
        color: var(--text-color);

        .number {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          height: 100%;
          width: 100%;
          color: #000;

          &.gone {
            color: #BDBDBD;
          }

          &.today {
            background: #EFF0F4;
          }

          &.between {
            background: #dee8fe;
          }

          &.active {
            color: #fff;
            background: #2F2F2F;
            font-size: 15px !important;
          }

          .circle-wrap {
            display: flex;

            .circle {
              margin: 1px;
              width: 6px;
              height: 6px;
              background: #62BF40;
              border-radius: 50%;
              border: 0.1px solid #2F2F2F;
            }
          }
        }
      }
    }

    .open-calendar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      color: #828282;
      font-size: 13px;
      font-weight: 500;
      border-top: 1px solid #F2F2F2;
      margin-top: 20px;

      .image-place {
        margin: 0 5px;
      }

      .rotated {
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
    }
  }
}
</style>
