export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: 'auth/login', name: 'auth.login', icon: 'genderless', component: () => import('pages/auth/login/Index'), },
      { path: 'auth/registration', name: 'auth.registration', icon: 'genderless', component: () => import('pages/auth/registration/Index'), },
      { path: 'auth/code', name: 'auth.code', icon: 'genderless', component: () => import('pages/auth/code/Index'), },
      { path: 'auth/privacy-policy', name: 'auth.privacy-policy', icon: 'genderless', component: () => import('pages/auth/privacy-policy/Index'), },
      { path: 'auth/policy-user', name: 'auth.policy-user', icon: 'genderless', component: () => import('pages/auth/policy-user/Index'), },
      { path: 'auth/password', name: 'auth.password', icon: 'genderless', component: () => import('pages/auth/password/Index'), },
      { path: 'auth/page-password-recovery/phone-recovery', name: 'auth.page-password-recovery.phone-recovery', icon: 'genderless', component: () => import('pages/auth/page-password-recovery/phone-recovery/Index'), },
      { path: 'auth/page-password-recovery/code', name: 'auth.page-password-recovery.code', icon: 'genderless', component: () => import('pages/auth/page-password-recovery/code/Index'), },
      { path: 'auth/page-password-recovery/password', name: 'auth.page-password-recovery.password', icon: 'genderless', component: () => import('pages/auth/page-password-recovery/password/Index'), },
      { path: 'auth/permissions/geolocation', name: 'auth.permissions.geolocation', icon: 'genderless', component: () => import('pages/auth/permissions/geolocation/Index'), },
      { path: '', name: 'auth.change-language', icon: 'genderless', component: () => import('pages/auth/change-language/Index'), },
      { path: 'auth/permissions/notifications', name: 'auth.permissions.notifications', icon: 'genderless', component: () => import('pages/auth/permissions/notifications/Index'), },
      { path: 'auth/permissions/camera-permissions', name: 'auth.permissions.camera-permissions', icon: 'genderless', component: () => import('pages/auth/permissions/camera-permissions/Index'), }
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: 'timesheet', name: 'timesheet', icon: 'genderless', component: () => import('pages/timesheet/Index'), },
      { path: 'timesheet/reason-late', name: 'timesheet.reason-late', icon: 'genderless', component: () => import('pages/timesheet/reason-late/Index'), },
      { path: 'qr/fixation', name: 'qr.fixation', icon: 'genderless', component: () => import('pages/qr/fixation/Index'), },
      { path: 'qr/scan', name: 'qr.scan', icon: 'genderless', component: () => import('pages/qr/scan/Index'), },
      { path: 'qr/result', name: 'qr.result', icon: 'genderless', component: () => import('pages/qr/result/Index'), },
      { path: 'qr/visit-mark', name: 'qr.visit-mark', icon: 'genderless', component: () => import('pages/qr/visit-mark/Index'), },
      { path: 'qr/reason-late', name: 'qr.reason-late', icon: 'genderless', component: () => import('pages/qr/reason-late/Index'), },
      { path: 'qr/employees', name: 'qr.employees', icon: 'genderless', component: () => import('pages/qr/employees/Index'), },
      { path: 'stats/stats', name: 'stats.stats', icon: 'genderless', component: () => import('pages/stats/stats/Index'), },
      { path: 'stats/employees/:id', name: 'stats.employees', icon: 'genderless', component: () => import('pages/stats/employees/Index'), },
      { path: 'stats/stats-employees/departments/:branchId', name: 'stats.stats-departments.departments', icon: 'genderless', component: () => import('pages/stats/stats-employees/Index'), },
      { path: 'stats/stats-employees/employees/:departmentId', name: 'stats.stats-employees.employees', icon: 'genderless', component: () => import('pages/stats/stats-employees/employees'), },
      { path: 'profile/profile', name: 'profile.profile', icon: 'genderless', component: () => import('pages/profile/profile/Index'), },
      { path: 'profile/my-data', name: 'profile.my-data', icon: 'genderless', component: () => import('pages/profile/my-data/Index'), },
      { path: 'profile/my-documents', name: 'profile.my-documents', icon: 'genderless', component: () => import('pages/profile/my-documents/Index'), },
      { path: 'profile/my-documents/page-photo', name: 'profile.my-documents.page-photo', icon: 'genderless', component: () => import('pages/profile/my-documents/page-photo/Index'), },
      { path: 'profile/my-documents/page-id-card', name: 'profile.my-documents.page-id-card', icon: 'genderless', component: () => import('pages/profile/my-documents/page-id-card/Index'), },
      { path: 'profile/my-requests', name: 'profile.my-requests', icon: 'genderless', component: () => import('pages/profile/my-requests/Index'), },
      { path: 'profile/documents-company', name: 'profile.documents-company', icon: 'genderless', component: () => import('pages/profile/documents-company/Index'), },
      { path: 'profile/create-request', name: 'profile.create-request', icon: 'genderless', component: () => import('pages/profile/create-request/Index'), },
      { path: 'profile/security', name: 'profile.security', icon: 'genderless', component: () => import('pages/profile/security/Index'), },
      { path: 'profile/security/password-change', name: 'profile.security.password-change', icon: 'genderless', component: () => import('pages/profile/security/password-change/Index'), },
      { path: 'profile/settings', name: 'profile.settings', icon: 'genderless', component: () => import('pages/profile/settings/Index'), },
      { path: 'profile/settings/change-language', name: 'profile.settings.change-language', icon: 'genderless', component: () => import('pages/profile/settings/change-language/Index'), },
      { path: 'profile/notifications/notifications-list', name: 'profile.notifications.notifications-list', icon: 'genderless', component: () => import('pages/profile/notifications/notifications-list/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: 'qr/test-scan', name: 'qr.test-scan', icon: 'genderless', component: () => import('pages/qr/test-scan/scan/Index'), },
    ], },
  ], },
]
