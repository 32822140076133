<template>
  <div class="c-modal-message">
    <div class="message">
      <div class="message-container">
        <div>
          <div class="image-wrap">
            <div class="image-place">
              <img :src="closeicon" @click="close">
            </div>
          </div>
          <div class="text" :class="{'big': !needComment}">
            <h3>{{ title }}</h3>
          </div>
        </div>
        <div v-if="needComment" class="textarea-wrap">
          <textarea v-model="comment" class="comment" :placeholder="$app.secure.clean($n.t('1.181'))" type="text"></textarea>
        </div>
        <div v-if="checkbox">
          <div class="check-box" @click="check = !check">
            <div class="square-wrap">
              <div class="square" :class="{'active': check}">
                <img v-if="check" :src="checkboxrow" alt="">
              </div>
            </div>
            <div class="agreement-title">{{ $app.secure.clean($n.t('1.182')) }}</div>
          </div>
        </div>
        <div class="buttons">
          <n-button flat class="modal-button cancel" @click="close">{{ no }}</n-button>
          <n-button flat class="modal-button agree" @click="agree">{{ yes }}</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import closeicon from '../../assets/close-modal.svg'
import checkboxrow from '../../assets/auth/checkboxrow.svg'

export default {
  name: 'CModalMessage',

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    needComment: {
      type: Boolean,
      default: true,
    },
    yes: {
      type: String,
      default: '',
    },
    no: {
      type: String,
      default: '',
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closeicon,
      checkboxrow,
      comment: '',
      check: false,
    }
  },
  methods: {
    agree() {
      this.$emit('agreed', this.comment, this.check)
      this.close()
    },
    close() {
      this.$emit('update:checked', this.check)
      this.$emit('update:show', false)
    },
  },
}
</script>

<style scoped lang="scss">
.c-modal-message {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .4);
  align-items: center;
  justify-content: center;
  .message {
    position: absolute;
    display: flex;
    background-color: #ffffff;
    border-radius: 16px;
    min-height: 190px;
    width: 90vw;
    max-width: 600px;
    .message-container {
      margin: 10px 18px 20px 18px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .image-wrap {
        display: flex;
        justify-content: flex-end;
        transform: translateY(-50px) translateX(10px);
      }


      .textarea-wrap {
        width: 100%;
        font-size: 15px;
        border: 2px solid #E0E0E0;
        border-radius: 16px;


        .n-message.danger {
          color: red;

          ::v-deep.question {
            border: 1px solid red;
          }
        }

        .comment {
          width: 100%;
          min-height: 100px;
          border: none;
          border-radius: 16px;
          padding: 15px;
          resize: none;
          font-size: 15px;
          background: #fff;
          outline: none;

        }

        //.comment:focus {
        //  border: 1px solid var(--primary) !important;
        //}
      }

      .check-box {
        min-height: 50px;
        display: flex;
        align-items: center;
        color: var(--text-color);
        margin-bottom: 15px;
        margin-top: 15px;

        .square-wrap {
          //transform: translate(0, -7px);

          .square {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            margin-right: 10px;
            border: 2px solid #BDBDBD;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
              background-color: #7C55FE;
            }
          }
        }
        .agreement-title {

          font-size: 15px;
          font-weight: 600;
        }
      }

      .text {
        h3 {
          margin: 0 0 4px 0;
          font-size: 15px;
          color: #000000;
          font-weight: 600;

        }
        &.big {
          h3 {
            font-size: 20px;
            text-align: center;
          }
        }
        p {
          min-height: 24px;
          margin: 0 0 12px 0;
          padding: 0;
          font-size: 1.05em;
          color: #6D6F7B;
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        .modal-button {
          width: 100%;
          height: 48px;
          margin-top: 8px;
          border-radius: 15px;
          font-weight: 600;
          font-size: 16px;
          &.agree {
            color: #fff;
            background-color: #62BF40;
            width: 46%;
          }
          &.cancel {
            color: #fff;
            background-color: #D14219;
            width: 46%;
          }
        }
      }
    }
  }
}
.close-modal {
  display: none;
}
</style>
