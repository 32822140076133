<template>
  <div class="c-page-title">
    <div class="left-part">
      <div class="text"> {{ leftText }} </div>
      <n-link v-if="backRoute" class="image-place" @click="back()">
        <img :src="backrow" alt="">
      </n-link>
    </div>
    <div class="mid-part">
      <div v-if="midText" class="text"> {{ midText }} </div>
    </div>
    <div class="right-part">
      <div class="text" @click="$router.push({ name: 'timesheet.statistics' })"> {{ rightText }} </div>
      <!--      <div v-if="rightText" class="text" @click="$router.push({ name: '' })">Выйти</div>-->
      <div v-if="close" @click="$router.push({ name: 'timesheet' })"> {{ close }} </div>
      <div v-if="filter">
        <c-filter-date-stats @update:value="handleDateChange" />
      </div>
    </div>
    <n-link v-if="notifications" class="right-link">
      <div class="image-place" @click="$router.push({ name: 'profile.notifications.notifications-list'})">
        <div v-if="countNotif > 0" class="counter"></div>
        <img :src="notificationsicon">
      </div>
    </n-link>
  </div>
</template>

<script>

import backrow from '../../assets/page-title-icon/back-arrow.svg'
// import backrowwhite from 'assets/back-row-white.svg'
import notificationsicon from '../../assets/notification-icon.svg'


export default {
  name: 'CPageTitle',
  props: {
    leftText: {
      type: String,
      default: '',
    },
    crossIcon: {
      type: Boolean,
      default: false,
    },
    midText: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
    phone: {
      type: Boolean,
      default: false,
    },
    backRoute: {
      type: String,
      default: '',
    },
    doRedirect: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
    notifications: {
      type: Boolean,
      default: false,
    },
    close: {
      type: String,
      default: '',
    },
    backWhite: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      backrow,
      notificationsicon,
      countNotif: 0,
    }
  },
  created() {
    if (this.notifications) {
      this.loadHasNotif()
    }
  },

  methods: {
    loadHasNotif() {
      $api.notifications.checkNew().then((res) => {
        this.countNotif = res.data.content.count
      })
    },
    back() {
      if (this.doRedirect) {
        this.$router.push({ name: this.backRoute, params: this.params, })
      }
      this.$emit('back', true)
    },
    handleDateChange(selectedDate) {
      this.$emit( 'update:date', selectedDate)
    },
  },
}
</script>

<style scoped lang="scss">


.c-page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding: 0 14px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  top: env(safe-area-inset-top);
  background: #fff;
  &::before{
    content: '';
    position: absolute;
    z-index: -1;
    top: -200%;
    right: 0;
    left: 0;
    bottom: 0;
    background: #fff;
  }


  .left-part {
    display: flex;
    .text {
      color: var(--text-color);
      font-size: 22px;
      font-weight: 700;
    }

    .image-place {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      width: 38px;
      z-index: 5;
      position: relative;

      .opacity-image-place {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 4;
        border-radius: 8px;
      }


      img {
        &.cross {
          width: 18px;
          height: 18px;
          object-fit: cover;
        }
        position: static;
      }
    }

  }

  .mid-part {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    line-height: 17px;
    width: 100%;
    .text {
      text-align: center;
      font-size: 17px;
      font-weight: 700;
      margin: 0;
    }
  }

  @keyframes blink-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .right-part {
    display: flex;
    .text {
      color: #828282;
      font-weight: 700;
      font-size: 15px;
    }
  }
  .right-link {
    display: flex;
    align-items: center;

    .image-place {
      position: relative;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;

      img {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
      }

      .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        background-color: red;
        color: #ffffff;
        z-index: 3;
        border-radius: 50%;
        font-size: 10px;
        width: 8px;
        height: 8px;
      }

      .image2 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 60%;
        height: 60%;

        img {
          width: 2px;
          height: 2px;
        }
      }

      .image2.animated {
        animation: blink-animation 1s infinite;
      }
    }
  }
}
</style>
