<template>
  <div class="date-picker">
    <div v-if="showBody" class="background-clicker" @click="showBody = false" />
    <div class="calendar" @click.stop>
      <div v-if="danger.length > 0" class="danger-message">
        {{ danger }}
      </div>
      <div
        v-if="(alwaysShowBody && showBody) || !alwaysShowBody"
        class="calendar-head" :class="{'body-open': showBody, 'danger': danger.length > 0}" @click="!text ? showBody = !showBody : ''">
        <div v-if="showBody" class="left-arrow" @click.stop="decrease">
          <n-icon icon="arrow-left" />
        </div>
        <div :key="cDate.getTime()" class="title">
          {{ date ? $app.date.format(date, 'date', true) : $app.secure.clean($n.t('1.221')) }}
        </div>
        <div v-if="showBody" class="right-arrow" @click.stop="increase">
          <n-icon icon="arrow-right" />
        </div>
        <div v-if="!showBody" class="image-place">
          <img :src="openArrow">
        </div>
      </div>
      <div v-show="showBody" class="calendar-body">
        <div class="month-title">{{ months[cDate.getMonth()] }}</div>
        <div class="days">
          <div v-for="(weekDay, index) in defDays" :key="index" class="wrap-day">
            <div class="day">{{ weekDay }}</div>
          </div>
        </div>
        <div class="dates">
          <div v-for="(week, index) in calendar()" :key="index" class="date-row">
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[1]?.numY === getNumberOfDay(cDate)}" @click="select(week[1]?.date)">
                {{ week[1]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[2]?.numY === getNumberOfDay(cDate)}" @click="select(week[2]?.date)">
                {{ week[2]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[3]?.numY === getNumberOfDay(cDate)}" @click="select(week[3]?.date)">
                {{ week[3]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[4]?.numY === getNumberOfDay(cDate)}" @click="select(week[4]?.date)">
                {{ week[4]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[5]?.numY === getNumberOfDay(cDate)}" @click="select(week[5]?.date)">
                {{ week[5]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[6]?.numY === getNumberOfDay(cDate)}" @click="select(week[6]?.date)">
                {{ week[6]?.index }}
              </div>
            </div>
            <div class="date-wrap">
              <div class="date" :class="{'selected': week[0]?.numY === getNumberOfDay(cDate)}" @click="select(week[0]?.date)">
                {{ week[0]?.index }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import openArrow from '../../../assets/page-timesheet/open-arrow.svg'

export default {
  name: 'CDatePicker',
  props: {
    danger: {
      type: String,
      default: '',
    },
    text: {
      type: Boolean,
      default: false,
    },
    preDate: {
      type: Date,
      default: () => {
        return new Date()
      },
    },
    alwaysShowBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      year: null,
      days: [],
      defDays: [],
      rWeeks: [],
      rDays: [],
      months: [],
      showBody: false,
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
        en: [ 'Mn', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', ],
      },
      localeMonths: {
        ru: [ 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь', ],
        kz: [ 'Қаңтар', 'Ақпан', 'Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан', ],
        en: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ],
      },
      date: this.preDate,
      cDate: this.preDate,
      openArrow,
    }
  },
  watch: {
    date() {
      this.$emit('update:date', this.date)
      this.$emit('update:value', this.date)
      this.$emit('change', this.date)
    },
    alwaysShowBody() {
      this.showBody = this.alwaysShowBody
    },
  },
  created() {
    this.init()
    this.$emit('update:date', this.date)
    this.$emit('update:value', this.date)
    this.showBody = this.alwaysShowBody
  },
  methods: {
    getColor(value) {
      const checkCount = value?.checks.length
      if (checkCount >= value?.countToCheck) {
        return '#64C038'
      }
      if (checkCount < value?.countToCheck && checkCount > 0) {
        return '#A70F61'
      }

      if (checkCount <= 0) {
        return '#FF0000'
      }
      return '#ffffff'
    },
    getCheckpoints() {
      this.$var('load', true)
      const query = 'date='+this.$app.date.format(this.cDate, 'date', true)
      $api.checklists.stats(this.id, this.user.id).with('checks').with('checkpoint').query(query).then((response) => {
        this.checkpoints = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    init() {
      const lang = localStorage.getItem('locale') || 'ru'
      console.log(lang)
      this.months = this.localeMonths[lang]
      this.day = this.localeDay[lang]
      this.defDays = this.localeDay[lang]
      this.cDate = this.date
      this.month = this.cDate.getMonth()
      this.year = this.cDate.getFullYear()
    },
    getNumberOfDay(today) {
      const startOfYear = new Date(today.getFullYear(), 0, 0)
      const diff = today - startOfYear

      const oneDay = 1000 * 60 * 60 * 24
      return Math.floor(diff / oneDay)
    },
    calendar() {
      let weeks = {}
      let week = 0
      let days = {}
      const dlast = new Date(this.cDate.getFullYear(), this.cDate.getMonth() + 1, 0).getDate()

      for (let i = 1; i <= dlast; i++) {
        days = {
          ...days,
          [new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i).getDay()]: {
            index: i,
            numY: Number(this.getNumberOfDay(new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i))),
            date: new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i),
          },
        }
        if (new Date(this.cDate.getFullYear(), this.cDate.getMonth(), i).getDay() === 0) {
          weeks = {
            ...weeks,
            [week]: days,
          }
          week += 1
          days = {}
        }
      }
      if (Object.keys(days).length !== 0) {
        weeks = {
          ...weeks,
          [week]: days,
        }
      }

      return weeks
    },
    select(date) {
      this.cDate = date
      this.date = this.cDate
      if (!this.alwaysShowBody) {
        this.showBody = false
      }
    },
    decrease() {
      this.date = this.cDate.setMonth(this.cDate.getMonth() - 1)
    },
    increase() {
      this.date = this.cDate.setMonth(this.cDate.getMonth() + 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.date-picker {

  .danger-message {
    color: red;
  }
  .background-clicker {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .calendar {
    position: relative;
    &-head {
      border: 1px solid var(--primary);
      background: #fff;
      min-width: 120px;
      height: 48px;
      display: flex;
      padding: 0 10px;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 15px;
      &.danger {
        border: 2px solid red;
      }
      border-radius: 12px;
      .n-icon {
        padding: 0 10px;
        color: #000000;
      }
      &.body-open {
        justify-content: space-between;
        border-radius: 10px 10px 0 0;
        border: 2px solid var(--primary);

        .title {
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          margin: 0;
        }
      }

      .title {
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        margin: 0;
      }
    }

    &-body {
      z-index: 100;
      border-radius: 0 0 12px 12px;
      border-left: 2px solid var(--primary);
      border-right: 2px solid var(--primary);
      border-bottom: 2px solid var(--primary);
      position: absolute;
      left: 0;
      right: 0;
      padding: 5px 0;
      background: #FFF;

      .month-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #000000;
        font-weight: 500;
      }

      .days {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;

        .wrap-day {
          padding: 0 4px;
        }

        .day {
          font-weight: 700;
          font-size: 10px;
          color: #000;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.selected {
            background-color: #2C117C1A;
          }
        }
      }

      .dates {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 7px;

        .date-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .date-wrap {
          padding: 5px 0;

          .date {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 12px;
            color: #000;

            &.selected {
              background-color: #2C117C1A;
            }
            &.inactive {
              color: #777676;
            }
          }
        }
      }
    }
  }
}
</style>
