<template>
  <div class="c-modal-comment">
  <!--    <c-page-title v-if="type === 'main'" mid-text="Фильтр"/>-->
    <div class="container">
      <div class="header">
        <div class="title">{{ $app.secure.clean($n.t('1.81')) }}</div>
        <div class="skip" @click="close()">{{ $app.secure.clean($n.t('1.82')) }}</div>
      </div>
      <div>
        <div class="instruction-wrap">
          {{ $app.secure.clean($n.t('1.83')) }}
        </div>
        <div class="textarea-wrap">
          <textarea v-model="comment" class="comment" type="text"></textarea>
        </div>
      </div>
      <n-button class="user-default-button purple" @click="agree">{{ $app.secure.clean($n.t('1.84')) }}</n-button>
    </div>
  </div>
</template>

<script>

// import closeicon from '../../assets/profile/close-icon.svg'
import imgRow from '../../assets/page-fixation/nav-link-arrow.svg'
import imgBack from '../../assets/page-title-icon/back-arrow.svg'
import imgClose from '../../assets/page-fixation/close-icon.svg'
import imgLabel from '../../assets/page-timesheet/label-icon.svg'
import CPageTitle from "../page-title/Index";
import CRadio from "../c-radio/Index";

export default {
  name: 'CModalComment',
  props: {
    yes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // closeicon,
      imgRow,
      imgBack,
      imgClose,
      imgLabel,
      select: false,
      type: 'main',
      openFilter: false,
      comment: '',
    }
  },
  methods: {
    agree() {
      this.$emit('agreed', this.comment);
      this.close()
    },
    close() {
      this.$emit('update:show', false)
    },
    toggleFilterWrap() {
      this.openFilter = false
      this.$emit( 'filter-action', this.openFilter )
    },
    show() {
      this.toggleFilterWrap()
    },
  },
}
</script>

<style scoped lang="scss">
.c-modal-comment {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #F5F6F9;
  padding: 50px 0;
  .message {

  }
}

.instruction-wrap {
  font-size: 13px;
  color: #828282;
  margin-top: 40px;
}

.textarea-wrap {
  width: 100%;
  font-size: 15px;
  padding: 15px 0 50px 0;
  border-bottom: 1px solid #EFF0F4;


  .n-message.danger {
    color: red;

    ::v-deep.question {
      border: 1px solid red;
    }
  }

  .comment {
    width: 100%;
    min-height: 140px;
    border: none;
    border-radius: 16px;
    padding: 15px 10px;
    resize: none;
    font-size: 15px;
    background: #fff;
    outline: none;

  }

  //.comment:focus {
  //  border: 1px solid var(--primary) !important;
  //}
}

.header {
  height: 50px;
  background: #fff;
  position: fixed;
  padding: 0 24px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .clean {
    font-size: 15px;
    font-weight: 700;
    color: #828282;
  }

  .image-place {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 22px;
    font-weight: 700;
  }

  .skip {
    color: #828282;
    font-size: 15px;
    font-weight: 700;
  }
}

.close-modal {
  display: none;
}
</style>
