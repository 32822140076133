import each from 'lodash/each'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import reject from 'lodash/reject'
import merge from 'lodash/merge'
import get from 'lodash/get'
import set from 'lodash/set'
import unset from 'lodash/unset'
import trim from 'lodash/trim'
import pull from 'lodash/pull'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import assign from 'lodash/assign'
import size from 'lodash/size'
import invert from 'lodash/invert'
import values from 'lodash/values'
import reverse from 'lodash/reverse'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import defer from 'lodash/defer'
import delay from 'lodash/delay'
import random from 'lodash/random'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import shuffle from 'lodash/shuffle'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isBoolean from 'lodash/isBoolean'
import { PushNotifications, } from '@capacitor/push-notifications'
import { Camera, } from '@capacitor/camera'
import { Capacitor, } from '@capacitor/core'

const setInRange = (value, a, b) => {
  const min = a < b ? a : b
  const max = a > b ? a : b
  
  if (value < min) return min
  if (value > max) return max
  
  return value
}

/**
 * @param {Array|Object} container
 * @param {Function} callback
 * @param {string} childrenName
 * @param {Function} parentData
 */
const eachDeep = (container, callback, childrenName = 'children', parentData = undefined) => {
  each(container, (item, key) => {
    let _parentData = parentData
    const data = (d) => {
      if (d === undefined) return _parentData
      _parentData = d
    }
    callback(item, key, data)
    const children = item[childrenName]
    if (isArray(children) || isObject(children)) {
      eachDeep(children, callback, childrenName, _parentData)
    }
  })
}

const mapDeep = (container, childrenName, callback, deep = 0) => {
  return map(container, (cont) => {
    cont = clone(cont)
    let children = cont[childrenName]
    if (isArray(children) || isObject(children)) {
      children = mapDeep(children, childrenName, callback, deep)
    }
    if (children !== undefined) {
      cont[childrenName] = children
    }
    return callback(cont)
  })
}

/**
 * @param {Array|Object} container
 * @param {Function} callback
 * @param {*} accumulator
 * @param {string} childrenName
 * @param {Function} parentData
 * @return {*}
 */
const reduceDeep = (container, callback, accumulator, childrenName = 'children', parentData = undefined) => {
  return reduce(container, (result, item, key) => {
    let _parentData = parentData
    const data = (d) => {
      if (d === undefined) return _parentData
      _parentData = d
    }
    result = callback(result, item, key, data)
    const children = item[childrenName]
    if (isArray(children) || isObject(children)) {
      result = reduceDeep(children, callback, result, childrenName, _parentData)
    }
    return result
  }, accumulator)
}

const promiseObjects = (array, key = 'promise') => {
  const promises = []
  
  array.forEach((object) => promises.push(object[key]))
  
  return Promise.all(promises).then((responses) => {
    return responses.reduce((result, response, i) => {
      result.push({
        ...array[i],
        response,
      })
      return result
    }, [])
  })
}

const isPromise = (obj) => {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'
}


const requestNotifications = () => {
  return new Promise((resolve, reject) => {
    PushNotifications.checkPermissions().then((result) => {
      if (result.receive !== 'granted') {
        PushNotifications.requestPermissions().then((permission) => {
          if (permission.receive === 'granted') {
            resolve({
              res: true,
              status: permission.receive,
            })
          } else {
            resolve({
              res: false,
              status: permission.receive,
            })
          }
        }).catch((error) => {
          resolve({
            res: false,
            status: error,
          })
        })
      } else {
        resolve({
          res: true,
          status: result.receive,
        })
      }
    })
  })
}
const requestCamera = () => {
  return new Promise((resolve, reject) => {
    Camera.checkPermissions().then((result) => {
      if (result.photos !== 'granted' || result.camera !== 'granted') {
        Camera.requestPermissions().then((permission) => {
          if (permission.camera === 'granted' && permission.photos === 'granted') {
            resolve({
              res: true,
              status: permission.camera + '|' + permission.photos,
            })
          } else {
            resolve({
              res: false,
              status: permission.camera + '|' + permission.photos,
            })
          }
        }).catch((error) => {
          resolve({
            res: false,
            status: error,
          })
        })
      } else {
        resolve({
          res: true,
          status: result.camera + '|' + result.photos,
        })
      }
    })
  })
}
const checkNotifications = () => {
  return new Promise((resolve) => {
    if (Capacitor.getPlatform() !== 'web') {
      PushNotifications.checkPermissions().then((result) => {
        if (result.receive === 'granted') {
          resolve({
            res: true,
            status: result.receive,
          })
        } else {
          resolve({
            res: false,
            status: result.receive,
          })
        }
      }).catch((error) => {
        resolve({
          res: false,
          status: error,
        })
      })
    } else {
      resolve({
        res: true,
        status: 'web',
      })
    }
  })
}
const checkGeo = () => {
  return new Promise((resolve) => {
    if (Capacitor.getPlatform() !== 'web') {
      Geolocation.checkPermissions().then((result) => {
        if (result.location === 'granted' && result.coarseLocation === 'granted') {
          resolve({
            res: true,
            status: 'granted',
          })
        } else {
          resolve({
            res: false,
            status: result.location,
          })
        }
      }).catch((error) => {
        resolve({
          res: false,
          status: error,
        })
      })
    } else {
      resolve({
        res: true,
        status: 'web',
      })
    }
  })
}
const checkCamera = () => {
  return new Promise((resolve) => {
    if (Capacitor.getPlatform() !== 'web') {
      Camera.checkPermissions().then((result) => {
        if (result.photos === 'granted' && result.camera === 'granted') {
          resolve({
            res: true,
            status: result.receive,
          })
        } else {
          resolve({
            res: false,
            status: result.receive,
          })
        }
      }).catch((error) => {
        resolve({
          res: false,
          status: error,
        })
      })
    } else {
      resolve({
        res: true,
        status: 'web',
      })
    }
  })
}

import { Geolocation, } from '@capacitor/geolocation'
import { Device, } from '@capacitor/device'

const getImei = async () => {
  const info = await Device.getId()

  return info.identifier
}


import { HuaweiGeolocation, } from 'mvl-huawei-geolocation'


const currentGeo = async () => {
  return new Promise((resolve, reject) => {
    if (Capacitor.getPlatform() !== 'ios') {
      HuaweiGeolocation.getDeviceInfo().then(async (res) => {
        if (res?.googlePlayServicesAvailable) {
          const permits = await Geolocation.checkPermissions()
          if (permits.location !== 'granted' && permits.coarseLocation !== 'granted') {
            Geolocation.requestPermissions()
          } else {
            const result = await Geolocation.getCurrentPosition()
            resolve(result)
          }
        } else {
          HuaweiGeolocation.getCurrentLocation().then((res) => {
            const result = {
              coords: {
                ...res,
                accuracy: 0,
                altitudeAccuracy: 0,
                altitude: 0,
                speed: 0,
                heading: 0,
              },
            }
            resolve(result)
          }).catch((error) => {
            reject(error)
          })
        }
      }).catch((error) => {
        reject(error)
      })
    } else {
      getGeo().then((res) => {
        resolve(res)
      })
    }
  })
}

const getGeo = async () => {
  const permits = await Geolocation.checkPermissions()
  if (permits.location === 'denied' && permits.coarseLocation === 'denied') {
    alert('Разрешите доступ к геоданным')
    return null
  } else {
    const result = await Geolocation.getCurrentPosition()
    return result
  }
}

const getMessage = (messages, field = null) => {
  if (field === null) {
    return messages.message
  }
  messages = messages.data
  if (messages[field]) {
    if (isArray(messages[field]) && messages[field].length !== 0) {
      return messages[field][0]
    } else {
      return messages[field]
    }
  } else {
    return ''
  }
}

const t = (key) => {
  return $n.get($app.store.getter('app.translates'), key, '')
}



export default () => ({
  each,
  map,
  reduce,
  find,
  filter,
  reject,
  merge,
  get,
  set,
  unset,
  trim,
  pull,
  includes,
  concat,
  assign,
  size,
  invert,
  values,
  reverse,
  clone,
  cloneDeep,
  defer,
  delay,
  random,
  sortBy,
  shuffle,
  debounce,
  throttle,
  isEqual,
  isObject,
  isArray,
  isFunction,
  isString,
  isBoolean,
  
  setInRange,
  mapDeep,
  reduceDeep,
  eachDeep,
  isPromise,
  promiseObjects,
  requestNotifications,
  checkNotifications,
  checkCamera,
  requestCamera,
  currentGeo,
  getMessage,
  checkGeo,
  getImei,
  t,
})
