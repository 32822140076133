<template>
  <div class="c-search-modal">
    <div class="container">
      <div>
        <div class="search-wrap">
          <div class="input-wrap">
            <n-input class="input-search" placeholder="Поиск" />
            <div class="image-place">
              <img :src="imgClose">
            </div>
          </div>
          <div class="close" @click="toggleSearchWrap()">Закрыть</div>
        </div>
        <div class="list-employees">
          <div class="employees-wrap" @click="$router.push({name: 'stats.employees'})">
            <div class="left-part">
              <div class="circle"></div>
              <div class="name">Маслова Т.Л.</div>
            </div>
            <div class="post">Инженер</div>
          </div>
          <div class="employees-wrap">
            <div class="left-part">
              <div class="circle"></div>
              <div class="name">Маслова Т.Л.</div>
            </div>
            <div class="post">Инженер</div>
          </div>
          <div class="employees-wrap">
            <div class="left-part">
              <div class="circle"></div>
              <div class="name">Маслова Т.Л.</div>
            </div>
            <div class="post">Инженер</div>
          </div>
          <div class="employees-wrap">
            <div class="left-part">
              <div class="circle"></div>
              <div class="name">Маслова Т.Л.</div>
            </div>
            <div class="post">Инженер</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import imgSearch from '../../assets/page-stats/search-icon.svg'
import imgClose from '../../assets/page-fixation/close-icon.svg'
import CPageTitle from '../page-title/Index'
import CRadio from '../c-radio/Index'

export default {
  name: 'CSearchModal',
  components: { CRadio, CPageTitle, },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    yes: {
      type: String,
      default: '',
    },
    no: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgSearch,
      imgClose,
      select: false,
      type: 'main',
      openFilter: false,
    }
  },
  methods: {
    agree() {
      this.$emit('update:agree', true)
      this.$emit('agreed')
      this.close()
    },
    close() {
      this.$emit('update:show', false)
    },
    toggleSearchWrap() {
      this.openFilter = false
      this.$emit( 'search-action', this.openFilter)
    },
  },
}
</script>

<style scoped lang="scss">
.c-search-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: #F5F6F9;
  padding: 50px 0;

}

.search-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-wrap {
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #D4D8E1;


    .input-search {
      height: 48px;

      ::v-deep {
        .n-content {
          padding: 9px 50px 9px 15px;
          background-color: transparent;
          border-color: transparent;

          color: var(--text-color);
          font-size: 15px;

        }
        ::-webkit-input-placeholder {
          color: #828282;
        }

      }
    }
    .image-place {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px;
      width: 12px;
      height: 12px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .image-place-right {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;

    }
  }

  .close {
    color: #828282;
    font-size: 15px;
    font-weight: 700;
  }

  .title {
    font-size: 17px;
    font-weight: 700;
    margin-left: 15px;
  }
}

.list-employees {
  padding-top: 10px;

  .employees-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    margin-top: 10px;
    height: 45px;
    border-bottom: 1px solid #F2F2F2;

    .left-part {
      display: flex;
      align-items: center;


      .circle {
        width: 6px;
        height: 6px;
        background: #62BF40;
        border-radius: 50%;
        margin-right: 8px;
      }

      .name {
        font-size: 15px;
      }
    }

    .post {
      color: #828282;
    }
  }
}

.close-modal {
  display: none;
}
</style>
