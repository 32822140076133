<template>
  <div class="c-mobile-appbar">
    <div class="appbar-links">
      <n-link
        v-if="$app.auth.user().readStats"
        class="appbar-link" :class="routeName === 'stats.stats' ? 'appbar-link' : 'appbar-link active'" :to="{ name: 'stats.stats'}">
        <div class="icon">
          <img v-if="routeName === 'stats.stats'" :src="imgStatsActive" alt="">
          <img v-else :src="imgStats" alt="">
        </div>
      </n-link>
      <n-link class="appbar-link" :class="routeName === 'qr.fixation' ? 'appbar-link' : 'appbar-link active'" :to="{ name: 'qr.fixation'}">
        <div class="icon">
          <img v-if="routeName === 'qr.fixation'" :src="imgQrActive" alt="">
          <img v-else :src="imgQr" alt="">
        </div>
      </n-link>
      <n-link class="appbar-link" :class="routeName === 'timesheet' ? 'appbar-link' : 'appbar-link active'" :to="{ name: 'timesheet'}">
        <div class="icon">
          <img v-if="routeName === 'timesheet'" :src="imgTimesheetActive" alt="">
          <img v-else :src="imgTimesheet" alt="">
        </div>
      </n-link>
      <n-link class="appbar-link" :class="routeName === 'profile.profile' ? 'appbar-link' : 'appbar-link active'" :to="{ name: 'profile.profile'}">
        <div class="icon">
          <img v-if="routeName === 'profile.profile'" :src="imgProfileActive" alt="">
          <img v-else :src="imgProfile" alt="">
        </div>
      </n-link>
    </div>
  </div>
</template>

<script>

import imgTimesheetActive from 'assets/mobile-app-bar-icon/timesheet-active-icon.svg'
import imgTimesheet from 'assets/mobile-app-bar-icon/timesheet-icon.svg'
import imgQrActive from 'assets/mobile-app-bar-icon/qr-icon-active.svg'
import imgQr from 'assets/mobile-app-bar-icon/qr-icon.svg'
import imgProfileActive from 'assets/mobile-app-bar-icon/profile-icon-active.svg'
import imgProfile from 'assets/mobile-app-bar-icon/profile-icon.svg'
import imgStatsActive from 'assets/mobile-app-bar-icon/stats-icon-active.svg'
import imgStats from 'assets/mobile-app-bar-icon/stats-icon.svg'

export default {
  name: 'CMobileAppbar',
  data() {
    return {
      imgTimesheetActive,
      imgTimesheet,
      imgQrActive,
      imgQr,
      imgProfileActive,
      imgProfile,
      imgStatsActive,
      imgStats,
    }
  },
  computed: {
    routeName() {
      return this.$route.name
    },
  },
}
</script>

<style lang="scss" scoped>
.c-mobile-appbar {
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 40px;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px -10px 25px 0px #0E1F4D0D;
  &::before{
    content: '';
    position: absolute;
    z-index: -1;
    right: 0;
    left: 0;
    top: 0;
    bottom: -200%;
    background: #fff;
  }


  @media screen and (max-width: 600px) {
    bottom: 0;
    padding-bottom: calc(env(safe-area-inset-bottom) - 15px);
    background-color: transparent;
  }


  .appbar-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    min-height: 80px;
    background-color: #fff;
    padding: 0 10px;

    .appbar-link {
      font-size: 11px;
      height: 50px;
      color: var(--text-color);
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      text-align: center;
      &:not(:last-child){
        &::before{
          content: '';
          opacity: .5;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
      &.active {
      opacity: 1;
        color: #75809E;
      }

      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 7px;
        height: 25px;
      }
    }
  }
}
</style>
