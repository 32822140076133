<template>
  <div class="c-filter-date-stats">
    <div class="select-wrap">
      <n-select :data="formattedDates" :value.sync="selectedDate" :readonly="true" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CFilterDateStats',
  props: {
    yes: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedDate: null,
      daysOfWeek: [],
      localeDay: {
        ru: [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
        kz: [ 'Дс', 'Сс', 'Ср', 'Бс', 'Жм', 'Сн', 'Жс', ],
        en: [ 'Mn', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', ],
      },
      weekDates: [],
      today: new Date(),
      selectDates: '',
    }
  },
  computed: {
    formattedDates() {
      return this.weekDates.map((dateObj) => {
        const isToday = dateObj.rawDate.toDateString() === this.today.toDateString()
        let addText = $app.secure.clean($n.t('1.24'))

        if (dateObj.rawDate.getDate() === this.today.getDate() - 1) addText = $app.secure.clean($n.t('1.62'))
        if (dateObj.rawDate.getDate() === this.today.getDate() - 2) addText = $app.secure.clean($n.t('1.63'))

        const formattedDate = dateObj.rawDate.toLocaleDateString('en-GB')
          .replace(/\//g, '.')

        const data = {
          title: `${addText}, ${formattedDate}`,
          day: addText,
          value: dateObj.rawDate,
          isToday: isToday,
        }

        if (isToday) {
          this.selectedDate = data
        }
        return data
      })
    },
  },
  watch: {
    selectedDate() {
      this.$emit('update:value', this.selectedDate)
    },
  },
  mounted() {
    this.setCurrentWeekDates()
  },
  methods: {
    setCurrentWeekDates() {
      const today = new Date()

      for (let i = -2; i <= 0; i++) {
        const specificDate = new Date(today) // Копия объекта `today`
        specificDate.setDate(today.getDate() + i)
        this.weekDates.push({
          rawDate: specificDate,
        })
      }
    },
    init() {
      const lang = localStorage.getItem('locale') || 'en'
      this.day = this.localeDay[lang]
    },
  },
}

</script>

<style scoped lang="scss">


.c-filter-date-stats {
  background: #fff;


  .select-wrap {
    min-width: 150px;


    :deep() {
      .n-select {
        height: 36px;
        .v-select {
          height: 36px;
        }
        .vs__selected-options {
          .vs__search {
            font-size: 13px;
          }
        }
        .vs__dropdown-toggle {
          min-height: 36px !important;
          min-width: 185px;
          border: 1px solid #E0E0E0;
        }
        .vs__dropdown-menu {
          min-width: 200px;
          position: absolute;
          left: -13px;
          top: 40px;
        }
        .vs__selected {
          font-size: 13px;
        }
        .vs__dropdown-option {
          &.today {
            background-color: #ffeeba;
          }
        }
      }
    }
  }

}

</style>
