<template>
  <div class="n-select" :class="{'small': small}">
    <label v-if="title">{{ title }}</label>
    <v-select
      v-if="!text"
      v-model="s_value"
      :multiple="Array.isArray(value)"
      :searchable="!readonly"
      :options="data"
      :label="label"
      :placeholder="placeholder"
      @input="update"
    >
      <!-- Слот для кастомной стрелки -->
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <img :src="arrow" class="custom-arrow" />
        </span> <!-- Вы можете заменить "🔽" на SVG или другой символ -->
      </template>
    </v-select>
    <div v-else class="text-place">
      <div v-if="!Array.isArray(value)">{{ value[label] }}</div>
      <div v-else>
        <span v-for="(v, i) in value" :key="i">{{ v[label] + (value.length > 1 ? (i < value.length-1 ? ', ' : '') : '') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-select/dist/vue-select.css'
import arrow from '../../../assets/page-timesheet/open-arrow.svg'

export default {
  name: 'NSelect',
  props: {
    value: { default: '', },
    text: { default: false, type: Boolean, },
    readonly: { default: false, type: Boolean, },
    title: { type: String, default: '', },
    placeholder: { type: String, default: '', },
    data: { type: Array, default: () => [], },
    label: { type: String, default: 'title', },
    small: { type: Boolean, default: false, },
    'update:value': { type: Function, default: () => {}, },
  },
  data() {
    return {
      s_value: this.value,
      arrow,
    }
  },
  watch: {
    value() {
      this.s_value = this.value
    },
  },
  methods: {
    update() {
      this.$emit('change', this.s_value)
      this['update:value'](this.s_value)
      this.$emit('update:value', this.s_value)
      this.$emit('select', this.s_value)
    },
  },
}
</script>

<style lang="scss" scoped>

.n-select {

  min-width: 120px;
  height: 50px;
  &.small {
    min-width: 20px;
    :deep() {
      .vs__actions {
        display: none;
        background: red;
        padding: 0 !important;
      }
      .vs__dropdown-menu {
        min-width: 0;
      }
    }
  }
  :deep(.vs__clear) {
      display: none;
  }
  :deep() {
    .vs__selected {
      background-color: #FFFFFF;
    }
    .vs__dropdown-option {
      white-space: normal !important;
    }
    .vs__dropdown-menu {
      min-width: 100px;
      border-radius: 16px;
    }
  }

  :deep(.vs__dropdown-toggle) {
    border: 1px solid #7C55FE;
    min-height: 48px;
    border-radius: 12px;
  }


  label {
    display: block;
    color: inherit;
    width: 100%;
    font-size: 1em;
    line-height: 1.2;
    text-align: left;
    padding: 6px 0;
  }

    .text-place {
      background-color: #FFFFFF;
      border-radius: var(--border-radius);
      padding: 9px 15px;
    }
    .v-select {
      background-color: #7C55FE;
      border-radius: 16px;

      .vs__dropdown-toggle {
        border: 1px solid #7C55FE;
        padding: 8px 8px 9px;

      }
      .vs__dropdown-menu {
        background-color: #7C55FE;
      }
    }
  
    label {
      font-size: 1.1em;
      font-weight: bold;
      padding-bottom: 12px;

    }

  .vs--open {
    .vs__open-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .vs__open-indicator {
    margin-right: 6px; /* Добавляем отступ справа */
  }
}
</style>
